<template>
  <div class="users">
    <Breadcrumb name1="义链宇宙" name2="愿望列表" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <!-- <el-col :span="4">
          <el-select v-model="queryInfo.post_state" clearable placeholder="请选择状态">
            <el-option label="审核中" value="1"></el-option>
            <el-option label="通过" value="2"></el-option>
            <el-option label="驳回" value="3"></el-option>
          </el-select>
        </el-col> -->
        <el-col :span="4">
          <el-select v-model="queryInfo.wish_state" clearable placeholder="请选择愿望状态">
            <el-option label="审核中" value="1"></el-option>
            <el-option label="通过" value="2"></el-option>
            <el-option label="驳回" value="3"></el-option>
            <el-option label="执行" value="3"></el-option>
            <el-option label="不执行" value="4"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button icon="el-icon-search" style="margin: 0px;" @click="getDataList"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="dataList" stripe style="width: 100%" border>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="wish_batch_id" label="批次">
          <template v-slot="scope">
            # {{scope.row.wish_batch.start_token_id}} - {{scope.row.wish_batch.end_token_id}}
          </template>
        </el-table-column>
        <el-table-column prop="token_id" label="TokenId"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <!-- <el-table-column prop="content" label="内容"></el-table-column> -->
        <el-table-column prop="images" label="图片">
          <template v-slot="scope">
            <el-image 
              style="width: 50px; height: 50px"
              :src="scope.row.images[0]" 
              :preview-src-list="scope.row.images">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="praise_num" label="点赞数"></el-table-column>
        <el-table-column prop="tread_num" label="点踩数"></el-table-column>
        <!-- <el-table-column prop="post_state" label="发布状态" :formatter="postState"></el-table-column> -->
        <el-table-column prop="wish_sate" label="愿望状态" :formatter="wishState"></el-table-column>
        <el-table-column prop="created_on" label="创建时间" width="160">
          <template v-slot="scope">
            {{ (scope.row.created_on * 1000) | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230px">
          <template v-slot="scope">
            <!-- 修改按钮 -->
            <el-button
              type="primary"
              size="mini"
              @click="showEditDialog(scope.row)"
            >审核</el-button>
            <el-button
              type="success"
              size="mini"
              @click="setWishState(scope.row.id, 3)"
            >执行</el-button>
            <el-button
              type="warning"
              size="mini"
              @click="setWishState(scope.row.id, 4)"
            >不执行</el-button>
            <!-- 删除按钮 -->
            <!-- <el-button 
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row.id)"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        @size-change="handleSizeChange"
        :page-size="queryInfo.page_size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      title="详情"
      :visible.sync="addDialogVisible"
      :close-on-click-modal=false
      width="50%"
      @close="addDislogClosed"
    >
      <!-- 内容主题区域 -->
      <el-form
        label-width="100px"
        ref="addForm"
        :model="addForm"
      >
        <el-form-item label="标题">
          <el-input v-model="title" disabled></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="content" disabled type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <div style="display: inline-block;" v-for="image in images" :key="image">
            <el-image
              style="width: 100px; height: 100px"
              :src="image"
              :preview-src-list="images"></el-image>
          </div>
        </el-form-item>
        <el-form-item label="状态" prop="wish_state">
          <el-select v-model="addForm.wish_state" placeholder="请选择状态">
            <el-option label="待审核" value="1"></el-option>
            <el-option label="通过" value="2"></el-option>
            <el-option label="驳回" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因（驳回）" prop="reason">
          <el-input v-model="addForm.reason" type="textarea"></el-input>
        </el-form-item>

      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDislogClosed()">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Wish',
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 搜索值
        // query: '',
        // 当前的页数
        page: 1,
        // 当前每次显示多少条数据
        page_size: 10,
        wish_state: '',
      },
      // 存放用户的数据和数量
      dataList: [],
      total: 0,
      // 控制用户对话框的显示和隐藏
      addDialogVisible: false,
      //修改时的对象id
      id: '',
      // 添加用户数据的对象
      addForm: {
        id: '',
        wish_state: '1',
        reason:'',
      },
      title: '',
      content: '',
      images: [],
    }
  },
  components: {
    Breadcrumb,
  },
  created() {
    this.getDataList()
  },
  methods: {
    //获取愿望列表
    async getDataList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/wish/list',
        params: this.queryInfo,
        timeout: 5000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          this.$message.success('获取愿望列表成功!')
          this.dataList = res.data.result.list
          if (this.dataList.length > 0){
            for (let i = 0; i < this.dataList.length; i++) {
              this.dataList[i].images = this.dataList[i].images.split(",");
            }
          } 
          // console.log(this.dataList)
          this.total = res.data.result.pager.total_rows
        })
        .catch((res) => {
          this.$message.error('获取愿望列表失败!')
        })
    },
    // 监听 page_size 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page_size = newSize
      this.getDataList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getDataList()
    },
    // 监听添加用户的对话框关闭事件
    addDislogClosed() {
      this.addDialogVisible = false;
      this.addForm = {
        id: '',
        wish_state: '1',
        reason:'',
      }
    },
    submitForm() {
      const _that = this
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          _that.addForm.wish_state = Number(_that.addForm.wish_state)
          _that
            .$http({
              method: 'put',
              url: '/api/v1/admin/wish/post-state',
              data: _that.addForm,
              timeout: 5000,
              headers: {
                token: window.sessionStorage.getItem('token'),
                'Content-Type': 'application/json; charset=UTF-8',
              },
            })
            .then((res) => {
              if (res.data.message == "success"){
                _that.$message.success('修改成功');
              }else{
                _that.$message.error(res.data.message)
              }
              _that.addDislogClosed();
              _that.getDataList();
            })
            .catch((res) => {
              return _that.$message.error('修改操作失败了哦,请检查数据后重试')
            })
          
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 展示编辑用于的对话框
    showEditDialog(row) {
      this.title = row.title
      this.content = row.content
      this.images = row.images
      this.addForm = {
        id: row.id,
        wish_state: row.wish_state.toString(),
        reason: row.reason,
      }
      this.addDialogVisible = true
    },

    // 根据id删除对应的用户信息
    async removeById(id) {
      // 询问用户是否删除用户
      const confirmRusult = await this.$confirm(
        '此操作将删除该愿望?',
        '删除该愿望',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      // console.log(confirmRusult)
      // 用户点击了删除,则返回字符串 confirm
      // 用户取消了删除,则返回字符串 cancel
      if (confirmRusult !== 'confirm') {
        return this.$message.info('已经取消了删除')
      }
      let _that = this
      _that
        .$http({
          method: 'delete',
          url: '/api/v1/admin/wish/' + id,
          timeout: 10000,
          headers: {
            token: window.sessionStorage.getItem('token'),
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
        .then((res) => {
          if (res.data.message == "success"){
            _that.$message.success('删除成功');
          }else{
            _that.$message.error(res.data.message)
          }
          this.getDataList()
        })
        .catch((res) => {
          return this.$message.error('该愿望删除失败')
        })
    },
    setWishState(id, wishState){
      let _that = this
      _that
        .$http({
          method: 'PUT',
          url: '/api/v1/admin/wish/wish-state',
          timeout: 10000,
          data: {id:id, wish_state:wishState},
          headers: {
            token: window.sessionStorage.getItem('token'),
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
        .then((res) => {
          if (res.data.message == "success"){
            _that.$message.success('操作成功');
          }else{
            _that.$message.error(res.data.message)
          }
          this.getDataList()
        })
        .catch((res) => {
          return this.$message.error('操作失败')
        })
    },
    // postState:function(row, index){
    //   if (row.wish_state == 1) {
    //     return '审核中' //根据自己的需求设定
    //   }else if(row.post_state == 2){
    //     return '通过' //根据自己的需求设定
    //   }else if(row.post_state == 3){
    //     return '驳回'
    //   }else if(row.post_state == 4){
    //     return '执行中'
    //   }else if(row.post_state == 5){
    //     return '不执行'
    //   }
    // },
    wishState:function(row, index){
      if (row.wish_state == 1) {
        return '审核中' //根据自己的需求设定
      }else if(row.wish_state == 2){
        return '通过' //根据自己的需求设定
      }else if(row.wish_state == 3){
        return '驳回'
      }else if(row.wish_state == 4){
        return '执行中'
      }else if(row.wish_state == 5){
        return '不执行'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
